import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { HomepageCallout } from 'gatsby-theme-carbon';
<HomepageCallout leftText="dsfadsf" rightText="sfgfdg" backgroundColor='#343444' />;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row mdxType="Row">
      <Column colLg={4} className="bx--type-expressive-heading-03" mdxType="Column">
        <div style={{
          padding: '2rem',
          paddingTop: '4.5rem'
        }}>
          <p><a parentName="p" {...{
              "href": "http://ninjatrader.com/LP/VendorDemo/"
            }}><img parentName="a" {...{
                "src": "/images/NinjaTrader_Logo.png",
                "alt": "Technologies"
              }}></img></a></p>
        </div>
        <div style={{
          padding: '2rem',
          width: "80%",
          margin: 'auto'
        }}>
          <img {...{
            "src": "/images/NinjaTrader_Monitor_Image.png",
            "alt": "Technologies"
          }}></img>
        </div>
      </Column>
      <Column colLg={8} className="bx--type-expressive-heading-03" mdxType="Column">
        <h2>{`Our Recommended Trading Platform`}</h2>
        <p>{`NinjaTrader® is our #1 recommended trading software preferred by traders worldwide
including our clients.
Download NinjaTrader & receive immediate FREE access to:`}</p>
        <p><strong parentName="p"><a parentName="strong" {...{
              "href": "http://ninjatrader.com/LP/VendorDemo/"
            }}>{`Download NinjaTrader`}</a>{` & receive immediate FREE access to:`}</strong>{` `}</p>
        <ul>
          <li parentName="ul">{`Real-time futures data`}</li>
          <li parentName="ul">{`Unlimited real-time forex data`}</li>
          <li parentName="ul">{`Advanced charting`}</li>
          <li parentName="ul">{`Trade simulator`}</li>
          <li parentName="ul">{`Strategy development and backtesting`}</li>
          <li parentName="ul">{`Connect to NinjaTrader Brokerage, Interactive Brokers, TD Ameritrade & more…`}</li>
        </ul>
        <p>{`NinjaTrader’s award-winning `}<a parentName="p" {...{
            "href": "http://ninjatrader.com/GetStarted"
          }}>{`trading platform`}</a>{` is consistently voted an industry leader by the trading community.  Featuring 1000s of Apps & Add-Ons for unlimited customization, NinjaTrader is used by over 60,000 traders for advanced market analysis, professional charting and fast order execution.`}</p>
        <p>{`For new traders, start preparing for the live markets with a free `}<a parentName="p" {...{
            "href": "http://ninjatrader.com/FreeLiveData"
          }}>{`trading simulator`}</a>{` featuring real-time  \\
market data.`}</p>
        <h2><a parentName="h2" {...{
            "href": "http://ninjatrader.com/LP/VendorDemo/"
          }}>{`Get Started for FREE!`}</a></h2>
      </Column>
    </Row>
    <hr></hr>
    <Row mdxType="Row">
      <Column colLg={4} className="bx--type-expressive-heading-03" mdxType="Column">
        <div style={{
          padding: '2rem',
          paddingTop: '4.5rem'
        }}>
          <p><a parentName="p" {...{
              "href": "http://kinetick.com/NinjaTrader"
            }}><img parentName="a" {...{
                "src": "/images/Kinetick_Logo.png",
                "alt": "Technologies"
              }}></img></a></p>
        </div>
        <div style={{
          padding: '2rem',
          width: "80%",
          margin: 'auto'
        }}>
          <img {...{
            "src": "/images/Kinetick_Badge.png",
            "alt": "Technologies"
          }}></img>
        </div>
      </Column>
      <Column colLg={8} className="bx--type-expressive-heading-03" mdxType="Column">
        <h2>{`Our Recommended Market Data Feed`}</h2>
        <p>{`Kinetick`}<sup>{`® `}</sup>{`delivers reliable, fast and cost-effective `}<a parentName="p" {...{
            "href": "http://kinetick.com/NinjaTrader"
          }}>{`market data`}</a>{` to help level the playing field for active traders.   Take advantage of unfiltered, real time quotes for stocks, futures and forex that exceed the expectations of the world’s most demanding traders, like us!    \\`}</p>
        <p>{`Get started with FREE end-of-day `}<a parentName="p" {...{
            "href": "http://kinetick.com/"
          }}>{`historical market data`}</a>{` directly through the NinjaTrader platform`}<span style={{
            "textDecoration": "underline"
          }}>{` `}</span>{`and learn how you can significantly reduce CME Group Globex exchange fees on real-time market data with Kinetick.`}</p>
        <h2><a parentName="h2" {...{
            "href": "http://www.kinetick.com/end-of-day"
          }}>{`Get Started with Free EOD Data`}</a></h2>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      